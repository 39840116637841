import '../sass/main.scss';

import '../fonts/WixMadeforText-Bold.woff2';
import '../fonts/WixMadeforText-BoldItalic.woff2';
import '../fonts/WixMadeforText-ExtraBold.woff2';
import '../fonts/WixMadeforText-ExtraBoldItalic.woff2';
import '../fonts/WixMadeforText-Italic.woff2';
import '../fonts/WixMadeforText-Medium.woff2';
import '../fonts/WixMadeforText-MediumItalic.woff2';
import '../fonts/WixMadeforText-Regular.woff2';
import '../fonts/WixMadeforText-SemiBold.woff2';
import '../fonts/WixMadeforText-SemiBoldItalic.woff2';

import './common/custom-select.js';
import './common/focus.js';
import './common/screen-width.js';
import './common/screen-height.js';
import './common/css-variables.js';
import './common/anchor-links.js';

import './layouts/cookies-notice.js';
import './layouts/site-header.js';
import './layouts/site-menu.js';
import './layouts/site-menu-bar.js';
import './layouts/site-footer.js';

import './common/on-screen.js';

import { initModals } from './common/modals.js';

(() => {
    initModals();
})();
